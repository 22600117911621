.search-wrapper{
    display: flex;
    align-items: center;
    padding: 10px 35px;
    column-gap: 1em;
    flex-wrap: wrap;
}

label .input-field-container {
    margin-top: 0.25em;
    
}